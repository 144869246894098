import React from "react";
import { Link } from "gatsby";

import bgImage from "../../img/homepage/top-section-bg-chp.png";

import BGLoopMp4 from "../../img/homepage/Amos_Insitute_Homepage_Video_Loop.mp4";
import BGLoopPlaceholder from "../../img/homepage/Amos_Insitute_Homepage_Video_Loop_Placeholder.png";

const showVideoOnClick = () => {
  document.querySelector(".video-wrapper video").style.display = "none";

  document.querySelector(".video-wrapper iframe").src =
    "https://player.vimeo.com/video/379389667?autoplay=1";
  document.querySelector(".video-wrapper .embed-container").style.display =
    "block";
};

const TopSection = (props) => {
  return (
    <>
      <div
        style={{
          background: `url(${bgImage})`,
          backgroundSize: "1440px auto",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center top",
        }}
      >
        <section className="container mx-auto pb-4 pt-6">
          <div className="w-full text-center">
            <div className="py-4 lg:py-10 px-6 lg:px-0 w-auto lg:w-10/12 mx-auto">
              <h1 className="text-3xl md:text-4xl px-0 lg:px-14 xl:px-18 max-w-screen-sm font-black pb-10 mx-auto">
                {props.heading}
              </h1>
              <p className="w-auto lg:w-9/12 pb-10 text-lg text-gray-700 block mx-auto xl:px-4 xl:w-9/12">
                Our{" "}
                <Link to="/cognitive-health-program/">
                  Cognitive Health Program
                </Link>{" "}
                is the most comprehensive and affordable way to introduce the
                proven benefits of the Bredesen Protocol into your life.
              </p>
              <Link to="/signup/">
                <button className="bg-secondary hover:bg-secondary-dark text-white font-bold py-2 px-4 rounded">
                  Get Started &#x02192;
                </button>
              </Link>
            </div>
          </div>
        </section>
        <section id="video-section">
          <div className="container mx-auto">
            <div className="w-10/12 lg:w-2/3 mx-auto p-4 bg-white rounded-xl shadow-lg">
              <div className="video-wrapper text-center mx-auto">
                <video
                  autoPlay
                  muted
                  loop
                  playsInline
                  preload="metadata"
                  onClick={showVideoOnClick}
                  poster={BGLoopPlaceholder}
                  alt="Learn About The Amos Institute"
                  className="rounded-xl"
                >
                  <source src={BGLoopMp4} type="video/mp4" />
                </video>
                <div className="embed-container">
                  <iframe
                    title="Amos Institute Cognitive Health Program Promo"
                    width="560"
                    height="315"
                    src=""
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default TopSection;
