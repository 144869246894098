import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/Layout";

import TopSection from "../components/homepage/TopSectionCHP";
import FeaturesSection from "../components/homepage/FeaturesSection";
import ProgramAspects from "../components/homepage/ProgramAspects";
import Testimonials from "../components/homepage/Testimonials";
import Founder from "../components/homepage/Founder";
import BottomCTA from "../components/homepage/BottomCTA";

class IndexPageTemplate extends React.Component {
  componentDidMount() {
    if (typeof Storage !== "undefined") {
      const urlParams = new URLSearchParams(window.location.search);
      const ref = urlParams.get("ref");

      if (ref === "google") {
        console.log("ref set.");
        localStorage.setItem("ref", ref);
      }
    }
  }

  render() {
    var heading = this.props.heading;
    var sectionOne = this.props.sectionOne;
    var sectionThree = this.props.sectionThree;

    return (
      <div id="index-page">
        <TopSection heading={heading}></TopSection>
        <FeaturesSection sectionOne={sectionOne} sectionThree={sectionThree} />
        <ProgramAspects />
        <Testimonials />
        <Founder />
        <BottomCTA />
      </div>
    );
  }
}

IndexPageTemplate.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  sectionOne: PropTypes.object,
  sectionThree: PropTypes.object,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        heading={frontmatter.heading}
        sectionOne={frontmatter.sectionOne}
        sectionThree={frontmatter.sectionThree}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        sectionOne {
          title
          description
        }
        sectionThree {
          title
          description
        }
      }
    }
  }
`;
