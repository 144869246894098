import React from "react";

const Testimonials = (props) => {
  return (
    <>
      <section className="py-24 mb-20" style={{ backgroundColor: "#F3F4F7" }}>
        <div className="container mx-auto">
          <div className="w-10/12 lg:w-2/3 mx-auto text-center">
            <div className="pb-10 leading-8 xl:leading-9 md:text-xl text-gray-700">
              &#12317;Amylee’s brilliant and inspirational teachings allows for
              pausing, revisiting the material, reflecting, and connecting with
              past knowledge. Her resources, links and aids are incredible.
              Office hours allows for the learner to ask questions. I often
              revisited the weekly resources which allowed me to master a
              particular segment before moving on. I am grateful to have had the
              opportunity to learn from Amylee. She has made a difference in my
              life and I am confident she will do the same for you.&#12318;
            </div>
            <div className="uppercase font-bold text-gray-400 tracking-widest">
              Susan R.
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
