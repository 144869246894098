import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import scheduleApptsImg from "../../img/homepage/schedule-appts.svg";
import decortiveSphere from "../../img/homepage/decoritive-sphere.png";

const FeaturesSection = (props) => {
  return (
    <>
      <section id="product-section">
        <div className="container">
          <div className="mx-auto text-center pt-0 lg:pt-6 pb-12 lg:pb-24">
            <h2 className="uppercase font-bold text-lg text-gray-400 tracking-widest">
              Features of our program
            </h2>
          </div>
          {/* Feature 1 */}
          <div className="flex flex-col lg:flex-row sm:mb-6 lg:mb-24">
            <div className="w-auto lg:w-5/12 px-4 md:px-12 lg:px-0 relative">
              <div
                style={{ top: "-110px", left: "-100px" }}
                className="absolute"
              >
                <img src={decortiveSphere} alt="" role="presentation" />
              </div>
              <div className="text-3xl font-extrabold pb-6 text-gray-900">
                Implement the Bredesen Protocol From the Comfort of <br />
                <span
                  style={{
                    boxShadow:
                      "inset 0 -.125em 0 0 #fff,inset 0 -.445em 0 0 rgb(169, 102, 170, .3)",
                  }}
                >
                  Your Own Home
                </span>
              </div>
              <p className="text-gray-600">{props.sectionOne.description}</p>
            </div>
            <div className="hidden lg:flex justify-end w-auto lg:w-7/12 px-6 md:px-12 lg:px-0">
              {" "}
              <StaticImage
                src="../../img/homepage/amylee-with-images.png"
                alt="Lesson Thumbnails"
                role="presentation"
                placeholder="blurred"
                layout="constrained"
                width={600}
                height={395}
              />
            </div>
          </div>
          {/* Feature 2 */}
          <div className="flex flex-row px-4 md:px-12 lg:px-0 sm:mb-6 lg:mb-24">
            <div className="hidden lg:flex w-6/12">
              {" "}
              <StaticImage
                src="../../img/homepage/office-hours.png"
                alt="Office Hours"
                role="presentation"
                placeholder="blurred"
                layout="constrained"
                width={400}
                height={393}
              />
            </div>
            <div className="w-auto lg:w-6/12">
              <div className="text-3xl font-extrabold pb-6 pt-6 text-gray-900">
                Every Week, <br />
                Get Advice
                <br />
                <span
                  style={{
                    boxShadow:
                      "inset 0 -.125em 0 0 #fff,inset 0 -.445em 0 0 rgb(169, 102, 170, .3)",
                  }}
                >
                  From the Experts
                </span>
              </div>
              <p className="text-gray-600">
                As part of the Amos Institute Community, you have access to
                weekly "Office Hours" where you can ask{" "}
                <Link to="/dietitian-nutritionist-or-health-coach/">
                  highly specialized RDNs{" "}
                </Link>
                your questions on how to implement the Bredesen Protocol. This
                vital resource allows our clients to connect the dots on the
                complexities of this program and maximize their success. This
                service comes included in the one-time program cost.
              </p>
            </div>
          </div>
          {/* Feature 3 */}
          <div className="flex flex-row mb-16 lg:mb-12 px-4 md:px-12 lg:px-0">
            <div className="w-auto lg:w-5/12">
              <div className="text-3xl font-extrabold pb-6 pt-6 text-gray-900">
                <span
                  style={{
                    boxShadow:
                      "inset 0 -.125em 0 0 #fff,inset 0 -.445em 0 0 rgb(169, 102, 170, .3)",
                  }}
                >
                  Schedule Appointments
                </span>{" "}
                & <br /> Take Your Treatment to the <br /> Next Level
              </div>
              <p className="text-gray-600">{props.sectionThree.description}</p>
            </div>
            <div className="hidden lg:flex justify-end w-7/12 relative">
              {" "}
              <img
                src={scheduleApptsImg}
                alt="Schedule Virtual Appointments"
                role="presentation"
              />
              <div
                style={{
                  top: "110px",
                  right: "-100px",
                  transform: "scaleX(-1)",
                }}
                className="absolute"
              >
                <img src={decortiveSphere} alt="" role="presentation" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeaturesSection;
