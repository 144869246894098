import React from "react";

import LessonsIcon from "../../img/homepage/feature-icons/v2/lessons.svg";
import OfficeHoursIcon from "../../img/homepage/feature-icons/v2/office-hours.svg";
import ResourcesIcon from "../../img/homepage/feature-icons/v2/resources.svg";
import RDNIcon from "../../img/homepage/feature-icons/v2/rdn.svg";
import UIIcon from "../../img/homepage/feature-icons/v2/ui.svg";
import MealPlansIcon from "../../img/homepage/feature-icons/v2/meal-plans.svg";

const ProgramAspects = (props) => {
  return (
    <>
      <section>
        <div className="container mx-auto pb-24">
          <div className="flex flex-col lg:flex-row pb-16 text-center lg:text-left px-6 lg:px-0">
            <div className="sm:w-full lg:w-1/3 mb-6 lg:mb-0">
              <h2 className="text-3xl font-extrabold text-gray-900">
                Unparalleled Value
              </h2>
            </div>
            <div className="sm:w-full lg:w-2/3 sm:pr-0 lg:pr-20 text-gray-600">
              We are confident that our Cognitive Health Program provides the
              greatest value of any Alzheimer's or Dementia treatment existing
              today.
            </div>
          </div>
          <div className="grid grid-flow-row grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-3 lg:gap-y-8 gap-x-8 px-4 lg:px-0">
            <div className="mb-6">
              <div className="mb-8">
                <img
                  className="rounded-3xl"
                  src={LessonsIcon}
                  role="presentation"
                  alt=""
                />
              </div>
              <div className="font-bold pb-4 text-gray-900">
                25+ High Quality Video Lessons
              </div>
              <p className="text-gray-600">
                Each with practical resources and evidence based strategies, as
                well as research and references designed to reinforce your
                success on the Bredesen Protocol.
              </p>
            </div>
            <div className="mb-6">
              <div className="mb-8">
                <img
                  className="rounded-3xl"
                  src={OfficeHoursIcon}
                  role="presentation"
                  alt=""
                />
              </div>
              <div className="font-bold pb-4 text-gray-900">
                Weekly Office Hours
              </div>
              <p className="text-gray-600">
                Receive answers and get advice directly from our registered
                dietitians as you go through the program.
              </p>
            </div>
            <div className="mb-6">
              <div className="mb-8">
                <img
                  className="rounded-3xl"
                  src={ResourcesIcon}
                  role="presentation"
                  alt=""
                />
              </div>
              <div className="font-bold pb-4 text-gray-900">
                Extensive Resources
              </div>
              <p className="text-gray-600">
                Every lesson comes with multiple additional resources designed
                to deepen your program and provide you with the added support
                you will need to be successful.
              </p>
            </div>
            <div className="mb-6">
              {" "}
              <div className="mb-8">
                <img
                  className="rounded-3xl"
                  src={MealPlansIcon}
                  role="presentation"
                  alt=""
                />
              </div>
              <div className="font-bold pb-4 text-gray-900">Meal Plans</div>
              <p className="text-gray-600">
                Access the Amos Institute's Ketoflex Nutrition Plan Guide,
                including a 7-day meal plan, recipes & grocery shopping list.
              </p>
            </div>
            <div className="mb-6">
              {" "}
              <div className="mb-8">
                <img
                  className="rounded-3xl"
                  src={UIIcon}
                  role="presentation"
                  alt=""
                />
              </div>
              <div className="font-bold pb-4 text-gray-900">Easy To Use</div>
              <p className="text-gray-600">
                Our online services are user friendly, easy to understand, and
                our support staff is the best in the business and is always here
                to help you.
              </p>
            </div>
            <div>
              {" "}
              <div className="mb-8">
                <img
                  className="rounded-3xl"
                  src={RDNIcon}
                  role="presentation"
                  alt=""
                />
              </div>
              <div className="font-bold pb-4 text-gray-900">
                Access to Comprehensive Services
              </div>
              <p className="text-gray-600">
                Schedule private appointments, get customized meal plans and
                supplement lists all from our highly trained staff of experts
                for an additional cost.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProgramAspects;
