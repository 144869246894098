import React from "react";
import { Link } from "gatsby";

import bredesenTestimonialBg from "../../img/homepage/dale-testimonial.png";

const Founder = (props) => {
  return (
    <>
      <section>
        <div className="container mx-auto">
          <div className="grid grid-cols-10 gap-4">
            <div className="col-start-1 col-end-10 lg:col-start-1 lg:col-end-6">
              <div className="flex flex-col justify-center px-4 lg:px-0">
                <h2 className="font-extrabold text-3xl pb-6 text-gray-900">
                  Our Founder
                </h2>
                <p className="text-gray-600 pb-6">
                  The Amos Institute was founded by Amylee Amos PhD, RDN, IFMCP a
                  certified specialist of the Bredesen Protocol who trained
                  under Dr. Bredesen. One of the only RDNs certified by the
                  Institute for Functional Medicine in the United States, Amylee
                  personally created this program after years of research.
                </p>{" "}
                <div>
                  <Link to="/our-founder/">Learn more about Amylee Amos →</Link>
                </div>
              </div>
            </div>
            <div className="hidden lg:block lg:col-start-6 lg:col-end-11">
              <div
                style={{
                  background: `url(${bredesenTestimonialBg})`,
                  backgroundSize: "70%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center top",
                  width: "580px",
                  minHeight: "400px"
                }}
                className="px-20 py-16"
              >
                <p className="text-white leading-7 px-14 pt-4">
                  "The Amos Institute was founded by Amylee Amos, who trained
                  extensively and is utilizing the ReCODE protocol very
                  successfully. Listen in as we discuss brain health, ReCODE,
                  ongoing research, and updates to our work".
                  <span className="block pt-4 uppercase font-bold text-gray-300">
                    Dale Bredesen MD
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Founder;
